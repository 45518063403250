import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'

const SuccessPage = () => (
   <Layout>
      <SEO title="Success!" />
      <div
         style={{
            padding: `3rem`,
            margin: `12.5rem 5rem 5rem`,
            backgroundColor: `#f3f0e7`,
         }}>
         <h1 style={{ margin: `0` }}>Success!</h1>
         <p style={{ margin: `0` }}>
            Your request has been processed successfully.
         </p>
         <Link
            to="/"
            className="button button--primary button--has-icon button--has-icon_left"
            style={{ marginTop: `2rem` }}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Our Home Page
         </Link>
      </div>
   </Layout>
)

export default SuccessPage
